#creativefontse-page {
    .clickBtnWhatsap {
        background-color: red;
    }
    .outerClass {
        position: sticky;
        top: 0;
        position: -webkit-sticky;
        z-index: 2;
    }
    .outerStylersList {
        padding-left: 20px;
        padding-right: 20px;
    }
    .stylerListItem {
        padding: 10px;
        background-color: #f1f1f1;
        border: 0.5px solid #ffc409;
        margin-top: 20px;
        border-radius: 5px;
        min-height: 40px;
        display: flex;
        justify-content: space-between;
    }
    .inputClassStyling {
        border: 1px solid rgb(0, 0, 0);
        padding-left: 10px !important;
    }
    .itemLeftFontsList {
        max-width: 95%;
    }
    .itemRightFontsList {
        // background: red;
    }
}
