#utilities-page {
    .outerCionCardBgwhatsapplass {
        background-color: #1ebea5;
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    .outerionCardBgfontsclass{
        background-color: #949626;
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    .mainDivClassOuter {
        // background-color: aqua;
        width: 100%;
        justify-content: center;
        display: flex;
    }
    .madeWith {
        text-align: center;
        position: fixed;
        bottom: 20px;
        left: 0;
        right: 0;
        font-family: cursive;
    }
    .iconHeartClass {
        color: red;
        vertical-align: middle;
        font-size: 21px;
    }
    @media screen and (min-width: 600px) {
        .outerMainDiv {
            display: flex;
            flex-direction: row;
        }
    }
}
